@use '../../variables';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 3%;

    .navigation {
        margin-bottom: 20px;
    }

    .section_title {
        width: 90%;
        display: flex;
        align-items: center;
        align-items: flex-start;
        flex-direction: column;


        h3 {
            color: variables.$app_red_color;
        }
    }

    .section_content {
        width: 100%;
        display: flex;
        min-height: 40vh;
        align-self: flex-start;
    }


    .content {
        display: flex;
        width: 90%;
        justify-content: flex-end;
        margin-bottom: 5%;

        .product_image_container {
            width: 50%;
            display: flex;
            align-items: center;

            img {
                width: 50%;
            }
        }

        .description_container {
            width: 30%;

            button {
                background-color: variables.$app_red_color;
                color: variables.$basic_white_color;
                width: 60%;
                height: 35px;
                font-weight: bold;
                border: none;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    .pdf_link {
        font-size: 22px;
        font-weight: 600;
        color: variables.$app_intense_grey_color;
        cursor: pointer;
    }

    .pdf_link:hover {
        color: grey;
    }
}