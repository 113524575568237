@use '../../variables';

.container {
    height: 100%;
    border-left: solid 1px variables.$app_switch_grey_color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5%;

    .header {
        color: variables.$app_red_color;
        font-size: 22px;
        font-weight: bold;
    }


    .text {
        font-size: 14px;
        flex-wrap: nowrap;
    }

    button {
        width: 120px;
        height: 40px;
        padding: 1%;
        background-color: variables.$basic_white_color;
        border: solid 1px variables.$app_switch_grey_color;

        a {
            color: black;
            text-decoration: none;
        }
    }
}