@use '../../variables';

.container {
    display: flex;
    width: 100%;
    z-index: 1000;
    justify-content: space-between;

    .left_side,
    .right_side {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        background-size: cover;
        background-position: center;

        .left_side {
            margin-right: 10px;
            position: relative;
            overflow: hidden;
        }

        .right_side {
            position: relative;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .text {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: rgba(255, 255, 255, 0.7);
            padding: 5px 10px;
            display: flex;
            flex-direction: column;
        }

        .text span {
            display: block;
        }

        .hover_text {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.7);
            padding: 5px 10px;
            display: none;
        }

        .left_side:hover .hover_text,
        .right_side:hover .hover_text {
            display: block;
        }


    }

    .part {
        position: relative;
        width: 33%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        img {
            width: 100%;
            height: auto;
            display: block;
        }

        .text {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 5px 10px;
            display: flex;
            flex-direction: row;
            color: white;
            height: 90%;
            width: 90%;

            .first_column {
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .second_column {
                width: 60%;
                display: flex;
                justify-content: flex-end;
                position: relative;
                align-items: center;
                flex-direction: column;
            }

            .second_column button {
                margin-top: auto;
                width: 50%;
                height: 25%;
                border: none;
                background-color: variables.$button_red_color;
                color: variables.$basic_white_color;
                font-size: 26px;
                font-weight: bold;
            }


            .bold {
                font-weight: bold;
                font-size: 27px;
            }

            .normal {
                font-weight: normal;
                font-size: 27px;
            }

            .light {
                font-weight: lighter;
                font-size: 18px;
            }
        }

        .hover_text {
            display: none;
            background: rgba(50, 50, 50, 0.9);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 10;
            box-sizing: border-box;
            font-size: 22px;
            width: 70%;
            height: 70%;
        }

        &:hover .hover_text {
            display: block;
        }
    }

    @media (max-width: 768px) {
        .part {
            width: 100%;
        }

        .hover_text {
            left: 0;
            top: auto;
            bottom: 0;
            transform: none;
            width: calc(100% - 20px);
            max-width: none;
        }
    }

}