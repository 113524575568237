@use '../../../variables';

.dropdownContainer {
    position: relative;
    display: inline-block;
    transition: height 0.3s ease;
    width: 400px;

    .dropdownButton {
        color: variables.$footer-border-color;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
            content: "";
            display: inline-block;
            margin-right: 8px;
            border-width: 6px 6px 0 6px;
            border-style: solid;
            border-color: black transparent transparent transparent;
            transition: transform 0.3s ease;
            align-self: center;
        }

        &.dropdownButtonOpen::before {
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent black transparent;
            transform: rotate(1);
        }
    }

    .dropdownContent {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: 0;
        width: 100%;

        .dropdownRow {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;

            .checkboxContainer {
                width: 10%;
                display: flex;
                justify-content: center;
                align-self: center;
            }

            .labelContainer {
                width: 80%;
            }
        }
    }

    .dropdownContentOpen {
        max-height: 500px;
    }
}