@use '../../variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .content {
        width: 80%;
    }

}