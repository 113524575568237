@use '../../variables';

.container {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px;

    input {
        border: solid 1px variables.$border_color;
        height: 30px;
        margin-right: 10px;
    }

    a {
        color: inherit;
    }
}