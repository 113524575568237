@use '../../../variables';

.container {
    margin-top: 3%;
    margin-left: 10px;

    .row {
        margin-top: 3%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {
            width: 100%;

            .title {
                font-size: 28px;
                font-weight: 600;
                color: variables.$app_default_red_color;
            }

            p {
                font-size: 14px;
            }

            .input_row {
                width: 28%;
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                color: variables.$basic_grey_color;

                input {
                    border: solid 1px variables.$app_switch_grey_color;
                }
            }
        }

    }
}