@use '../../variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .table_container {
        width: 90%;
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            border-bottom: solid 0.1rem variables.$app_switch_grey_color;
            padding: 1%;
            transition: max-height 0.3s ease-in-out, border-bottom 0.3s ease-in-out;

            .column {
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                span {
                    font-weight: bold;
                }

                .product_value {
                    font-weight: normal;
                }

                button {
                    background-color: variables.$button_red_color;
                    color: variables.$basic_white_color;
                    width: 120px;
                    height: 50px;
                    border: none;
                    font-size: 14px;
                    font-weight: bold;
                }

                .red_icon {
                    color: variables.$button_red_color;
                }
            }
        }

        .expandedRow {
            max-height: 0;
            overflow: hidden;
            padding: 0 2%;
            transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
            border-bottom: solid 0.1rem variables.$app_switch_grey_color;

            &.expanded {
                min-height: 100px;
                padding: 1% 2%;
            }
        }

        .border_none {
            border-bottom: none;
        }

        .pdf_link {
            font-size: 20px;
            font-weight: 600;
            color: variables.$app_intense_grey_color;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
        }

        .pdf_link:hover {
            color: grey;
        }
    }

    .grey_background {
        background-color: variables.$light_grey_color;
    }

    .white_background {
        background-color: white;
    }

    .grey_background:hover,
    .white_background:hover {
        background-color: variables.$app_switch_grey_color;
        transition: background-color 0.3s ease-in-out;
    }
}