@use "../../../variables";

.reset_password a {

    color: variables.$basic_black_color;
    font-size: 13px;
    font-family: variables.$second_font_family;
    text-decoration: none;
}

.reset_password a:hover {
    font-weight: bold;
}