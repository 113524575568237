@use '../../../variables';

.container {
    margin-top: 3%;

    .row {
        margin-top: 3%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {

            .title {
                font-size: 28px;
                font-weight: 600;
                color: variables.$app_default_red_color;
            }

            p {
                font-size: 14px;
            }
        }

    }
}