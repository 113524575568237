@use '../../../variables';

.mainWrapper {
    display: flex;
    background-image: url("../../../assets/img/fixcenter-background.png");
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .row {
        display: flex;
        flex-direction: row;
        width: 100vw;
        min-height: 310px;
        justify-content: center;

        .title {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .title_text_0 {
                font-weight: 300;
                font-size: 2vw;
                line-height: initial;
                color: variables.$basic_black_color;
            }

            .title_text_1 {
                font-size: 2vw;
                line-height: 0.25;
                color: variables.$app_red_color;
                font-weight: bold;
            }

            .title_text_2 {
                color: variables.$basic_black_color;
                line-height: 3;
                font-size: 1vw;
            }

            button {
                background: variables.$app_red_color;
                color: variables.$basic_white_color;
                border: none;
                padding: 14px 40px;
                font-size: 1.2vw;
                text-transform: uppercase;

                &:hover {
                    font-weight: bold;
                }
            }

        }

        .product_zoom {
            width: 25%;

            img {
                width: 100%;
                height: 100%;
            }

            .fade_disable {
                opacity: 0;
            }

            .fade_active {
                opacity: 1;
                transition: opacity 0.5s ease-in-out;

            }
        }

        @media screen and (max-width: 990px) {
            .product_zoom {
                display: none;
            }
        }
    }
}