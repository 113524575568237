@use "../../../variables";

.register_now a {

    color: variables.$basic_black_color;
    font-size: 13px;
    font-family: variables.$second_font_family;
    text-decoration: none;

}

.register_now a:hover {
    font-weight: bold;
}