@use '../../variables';

.container {
    display: flex;

    .filters_menu {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .content {
        padding: 5%;
        width: 75%;
        height: auto;

        .category_header {
            p {
                font-size: 42px;
                font-weight: 600;
                color: variables.$app_red_color
            }

            .notification {
                color: variables.$basic_grey_color;
                font-size: 30px;
                font-weight: 600;
            }
        }

        .cards_container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .single_product {
                width: 25%;
                height: 250px;
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (max-width: variables.$first_breakpoint) {
    .container {
        .content {
            .cards_container {
                .single_product {
                    width: 30%;
                }
            }
        }
    }
}

@media screen and (max-width: variables.$fourth_breakpoint) {
    .container {
        .content {
            .cards_container {
                .single_product {
                    width: 50%;
                }
            }
        }
    }
}