@use '../../variables';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
        margin-top: 5%;
        width: 80%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;

        .single_product {
            width: 25%;
        }

    }
}

@media screen and (max-width: variables.$first_breakpoint) {
    .container {
        .wrapper {
            .single_product {
                width: 30%;
            }
        }
    }
}

@media screen and (max-width: variables.$second_breakpoint) {
    .container {
        .wrapper {
            .single_product {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: variables.$fourth_breakpoint) {
    .container {
        .wrapper {
            .single_product {
                width: 100%;

            }
        }
    }
}