@use '../../../variables';

.table_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        height: 50px;
        border-top: solid 1px variables.$app_switch_grey_color;

        .column {
            display: flex;
            align-items: center;
            font-size: 13px;
        }

        .bold {
            font-weight: 600;
            font-size: 16px;
        }

        .number_column {
            width: 8%;
        }

        .building_site_column {
            width: 37%;
        }

        .status_column {
            width: 10%;
        }

        .date_column {
            width: 12%;
        }

        .user_column {
            width: 18%;
        }

        .buttons_column {
            width: 20%;
            display: flex;
            justify-content: space-between;

            button {
                background-color: variables.$input_option_accent_color;
                color: variables.$basic_white_color;
                font-weight: 500;
                border: none;
                width: auto;
                min-height: 35px;
                padding: 5px;
                padding-left: 8%;
                padding-right: 8%;
                font-weight: 500;
                font-size: 14px;

                &:disabled {
                    background-color: variables.$disabled_button_color;
                }
            }
        }
    }
}