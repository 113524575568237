@use '../../../variables';

.buttons_container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
        background-color: variables.$input_option_accent_color;
        color: variables.$basic_white_color;
        font-weight: 500;
        border: none;
        min-width: 60px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;

        a {
            text-decoration: none;
            color: white;
        }

        &:disabled {
            background-color: variables.$disabled_button_color;
        }
    }

}