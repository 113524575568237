@use '../../variables';

.main_container {
    padding: 2%;
    margin-left: 10%;
    width: 70%;
    font-size: 1vw;

    img {
        width: 100%;
        height: auto;
    }

    h2 {
        color: variables.$basic_static_page_header_color;
        font-size: 2.2vw;
    }

    strong {
        font-size: 1vw;
    }
}