@use '../../../variables';

.container {
    min-height: 300px;
    margin-top: 30px;

    select {
        border: solid 1px variables.$border_color;
        color: solid 1px variables.$basic_grey_color;
    }

    .form_row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .input_label {
            font-weight: 500;
        }
    }

    .first_row {
        width: 100%;

        .input_label {
            width: 25%;
        }

        select {
            width: 50%;
        }
    }

    .table_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 10%;
        margin-top: 10px;

        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            height: 50px;
            border-top: solid 1px variables.$app_switch_grey_color;
            align-items: center;
            font-size: 13px;

            .column {
                button {
                    background-color: variables.$input_option_accent_color;
                    color: variables.$basic_white_color;
                    font-weight: 500;
                    border: none;
                    min-width: 160px;
                    padding: 5px;
                    padding-left: 15px;
                    padding-right: 15px;

                    &:disabled {
                        background-color: variables.$disabled_button_color;
                    }
                }
            }

            .bold {
                font-weight: 600;
                font-size: 16px;
            }

            &:hover {
                background-color: variables.$app_switch_grey_color;
                transition: background-color 0.3s;
            }
        }

        .no_hover:hover {
            background-color: initial;
            transition: none;
        }

        .odd_row {
            background-color: variables.$light_grey_color;
        }
    }
}