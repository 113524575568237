@use '../../variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .product_name {
        width: 30%;
        height: 100%;
        margin-left: 3%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: underline;
        color: variables.$basic_black_color;

        .link {
            font-size: 22px;
            font-weight: 400;
            cursor: pointer;
            color: black;
        }

        .link:hover {
            font-weight: 600;
            color: black;
        }
    }

    .product_image {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
        padding: 2%;

        .image_container {
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: white;
                border-radius: 100%;
                -webkit-box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
                -moz-box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
                box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
                padding: 10px;
            }
        }

    }
}

@media screen and (max-width: variables.$fourth_breakpoint) {
    .container {
        .product_image {
            width: 120px;
            height: 120px;
        }
    }
}