.ring_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 100%;
    pointer-events: none;
}

.ring {
    width: 4cm;
    height: 4cm;
    border: 0.4cm solid #f3f3f3;
    border-top: 0.4cm solid #800000;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}