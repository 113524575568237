@use '../../../variables';

.container {
    width: 100%;

    .header,
    .sub_header {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 10px;
    }

    .sub_header {
        color: variables.$footer-border-color;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button_container {
            width: 30%;
            height: 90%;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                background-color: variables.$input_option_accent_color;
                color: variables.$basic_white_color;
                font-weight: 500;
                font-size: 16px;
                border: none;
                margin-right: 10%;
                padding: 5px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    .buttons_container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5%;

        .buttons_wrapper {
            width: 27%;
        }
    }

    .shorter_row {
        width: 35%;
    }

    .underlined_row {
        border-bottom: solid 1px variables.$app_intense_grey_color;
        margin-bottom: 10px;
    }

    .bold {
        font-weight: bold;
    }
}