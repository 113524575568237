@use '../../../variables';

.breadcrumbs {
    border: 1px solid #cbd2d9;
    border-radius: 0.3rem;
    display: inline-flex;
    overflow: hidden;
}

.breadcrumbs__item {
    background: #fff;
    color: #333;
    outline: none;
    padding: 0.75em 0.75em 0.75em 1.25em;
    position: relative;
    text-decoration: none;
    transition: background 0.2s linear;
    font-weight: 500;
    font-size: 14px;
    margin-left: 15px;
}

.breadcrumbs__item:after,
.breadcrumbs__item:before {
    background: white;
    bottom: 0;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    content: "";
    left: 100%;
    position: absolute;
    top: 0;
    transition: background 0.2s linear;
    width: 3em;
    z-index: 1;
}

.breadcrumbs__item:before {
    background: #cbd2d9;
    margin-left: 1px;
}

.breadcrumbs__item:last-child {
    border-right: none;
}

.active {
    background-color: variables.$light_grey_color;
    color: variables.$tile_color;
    font-weight: 400;
}