@use '../../../variables';

.container {
    background: variables.$basic_grey_color;
    color: variables.$basic_white_color;
    display: grid;
    font-family: variables.$font-family;

    .footer_container {
        width: 100%;
        display: flex;
        justify-content: center;


        .columns {
            width: 70%;
            display: flex;
            justify-content: center;

            .footer_section {
                width: 20%;
                margin: 2%;
                display: flex;
                flex-direction: column;

                p,
                a {
                    font-size: 15px;
                }

                a {
                    text-decoration: none;
                    color: variables.$basic_white_color;
                }

                img {
                    filter: variables.$convert_to_white_color;
                    margin-left: 5px;
                }
            }
        }
    }
}


@media screen and (max-width: 1300px) {

    .newsletter,
    .logo_wrapper,
    .columns,
    .copyright {
        padding: 0 20px;
    }

    .footer_container {
        .columns {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1115px) {
    .columns {
        .second_column {
            .phone {
                .number {
                    .week_info {
                        width: 150px !important;
                    }
                }
            }
        }
    }

    .footer_container {
        width: 100%;

        .columns {
            width: 90%;
            flex-wrap: wrap;

            .footer_section {
                min-width: 40%;
            }
        }
    }
}

@media screen and (max-width: 894px) {
    .footer_container {
        .newsletter {
            margin: 50px auto;

            .left_side {
                width: 100%;
            }

            .right_side {
                width: 100%;
                margin-top: 25px;
            }
        }

        .logo_wrapper {
            text-align: center;
            margin-bottom: 20px;
        }

        .columns {

            .first_column {
                width: 100%;
                align-items: center;
                margin-top: 0;

                .address {
                    margin-bottom: 10px;
                    text-align: center;
                }

                .follow_us {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .second_column {
                width: 100%;
                margin-top: 20px;
                text-align: center;

                .phone {
                    margin-top: 25px;
                    justify-content: center;
                    gap: 0;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: calc(50% - 90px);
                    }

                    .number {
                        .week_info {
                            width: 260px !important;
                        }
                    }
                }

                .email {
                    justify-content: center;
                    margin-top: 0;
                }
            }

            .third_column,
            .fourth_column,
            .fifth_column {
                display: none;
            }
        }

        .line {
            margin-top: 30px;
        }

        .copyright {
            flex-direction: column;
            margin-bottom: 25px;

            .left {
                min-width: auto;
                margin-bottom: 15px;
            }

            .right {
                flex-direction: column;

                .UEInfo {
                    margin-right: 0;
                    margin-bottom: 5px;
                }

                .copyright_label {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }

    .footer_container {
        .columns {
            width: 90%;
            padding: none;

            .footer_section {
                display: flex;
                min-width: 100%;
            }


        }
    }


}