@use '../../../variables';


.container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;


    .menu {
        width: 25%;
        margin-top: 50px;
    }

    .form_container {
        display: flex;
        flex-direction: column;
        width: 45%;
        margin-left: 2%;
        height: 100%;

        h4 {
            width: 100%;
        }

        .field_container {
            flex-direction: column;
            width: 95%;
            font-family: variables.$font-family;

            .field_input {
                width: 100%;
                padding: 0.5rem;
                height: 40px;
                border: 1px solid variables.$app_intense_grey_color;
                border-radius: 3px;
                font-weight: bold;
                margin-top: 20px;

                &:-webkit-autofill {
                    -webkit-background-clip: text;
                }

                font-weight: 200;
            }
        }

        .remember_reset_container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;

        }

        .submit_button_container {
            align-self: flex-start;
            width: 70px;
            height: 40px;
            margin-top: 5%;

            button {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                background-color: variables.$app_red_color;
                text-transform: uppercase;
                color: variables.$basic_white_color;
                font-weight: 500;

            }
        }
    }

    .basic_information {
        height: 120px;
        width: 30%;
        margin-top: 50px;
    }
}

@media screen and (max-width: 1370px) {
    .main_container {
        width: 80%;
    }
}