@use '../../../variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .content {
        width: 80%;
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .form {
            margin-top: 25px;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            flex-direction: column;

            .row {
                width: 90%;
                display: flex;
                justify-content: space-between;
                text-align: center;
                margin-bottom: 20px;

                .label {
                    width: 10%;
                    text-align: right;
                    font-size: 14px;
                }

                input,
                select {
                    width: 85%;
                    border: solid 1px variables.$app_switch_grey_color
                }
            }

            .button_row {
                display: flex;
                justify-content: flex-end;

                button {
                    background-color: variables.$input_option_accent_color;
                    color: variables.$basic_white_color;
                    font-weight: 500;
                    border: none;
                    min-width: 100px;
                    padding: 5px;
                    padding-left: 15px;
                    padding-right: 15px;

                }
            }
        }
    }

}