@use '../../../variables';

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 300px;
    margin-left: 20px;

    input,
    select {
        border: solid 1px variables.$border_color;
    }

    .form_row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .input_label {
            font-weight: 500;
        }
    }

    .first_row {
        width: 100%;

        .input_label {
            width: 25%;
        }

        select {
            width: 50%;
        }
    }

    .second_row {
        display: flex;
        justify-content: space-between;
        height: 200px;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            width: 25%;
            gap: 30px;
        }

        input,
        textarea {
            width: 90%;
        }

        input[type='checkbox'] {
            width: 4%;
            border: solid 1px;
        }

    }


}