@use '../../../variables';

.container {
    display: flex;
    flex-direction: row;
    width: 100vw;

    .menu {
        width: 25%;
    }

    .basic_information {
        height: 120px;
        width: 30%;
    }

    .form {
        display: flex;
        width: 45%;

        input:-webkit-autofill {
            -webkit-background-clip: text;
        }

        input,
        select {
            border: 2px solid variables.$app_intense_grey_color;
            border-radius: 3px;

        }

        input[type=radio] {
            accent-color: variables.$input_option_accent_color;
            width: 15px;
            height: 15px;
        }

        .center_section {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;

            .section_title {
                width: 20%;
                font-size: 18px;
                text-transform: uppercase;
                color: variables.$tile_color;
                display: flex;
                justify-content: flex-end;
            }

            .basic_data,
            .address_data,
            .checkbox_container {
                width: 70%;

                .section_title {
                    font-size: 20px;
                    text-transform: uppercase;
                    color: black;
                    width: 100%;
                    text-align: start;
                    display: flex;
                    justify-content: flex-start;
                    padding-bottom: 2%;

                }
            }

            .checkbox_container {
                .field_name {
                    width: 60%;
                    text-align: left;
                }

                .section_title {
                    width: 50%;
                }

                .normal_field {
                    width: 40%;
                    display: flex;
                    justify-content: flex-start;
                }
            }

            .button_container {
                align-self: flex-end;
                height: 50%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .disabledButton {
                    background-color: variables.$basic_grey_color;
                }

                button {
                    height: 3rem;
                    width: 10rem;
                    border: none;
                    outline: none;
                    font-family: variables.$second_font_family;
                    text-transform: uppercase;
                    color: variables.$basic_white_color;
                    border-radius: 3px;
                    background-color: variables.$tile_color;
                }
            }

        }

        .form_row {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 2%;

            .small_text {
                font-size: 7px;
            }

            .error_border {
                border: solid 1px red;
            }

            .field_name {
                text-align: end;
                width: 20%;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .normal_field {
                width: 60%;
                margin-left: 30px;
            }

            .small_field {
                width: 10%;

                input {
                    width: 100%;
                }
            }

            .country_dropdown {
                width: 60%;
                margin-left: 30px;

                option {
                    background-color: variables.$tile_color;
                    color: variables.$basic_white_color;

                    &:hover {}
                }
            }

            .language_dropdown {
                width: 60%;
                margin-left: 30px;

                option {
                    background-color: variables.$tile_color;
                    color: variables.$basic_white_color;

                    &:hover {}
                }
            }

            .contact_title_radio_group {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin-top: 1%;

                .control {
                    min-width: 80px;
                    display: flex;
                    gap: 6%;

                    input {}

                    label {
                        transform: translateY(-20%);
                    }
                }
            }


        }


        .special_form_row {
            width: 100%;

            .inputs_container {
                width: 65%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .normal_field {
                    width: 60%;

                    input {
                        width: 100%;
                    }
                }

                .small_inputs_container {
                    width: 30%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .divider {
                        font-size: 18px;
                        text-align: center;
                    }

                    .small_field {
                        width: 40%;

                        input {
                            width: 25%;
                        }

                    }
                }
            }



        }


        .errorText {
            color: variables.$basic_red_color;
            margin-left: 25px
        }

    }

    .error_border {
        border: solid 2px variables.$basic_red_color;
    }
}