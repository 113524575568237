@use '../../variables';

.container {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: flex-end;
    background-image: url('../../assets/img/fixcenter-footer-banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 50px;
    margin-top: 5px;
    position: relative;
    overflow: hidden; // Zmiana zapewniająca, że nic nie wykracza poza granice kontenera

    &.reset_animation {
        .left_side {
            transform: translateX(-100%);
            transition: none; // Wyłącza animację przejścia
        }
    }

    &.isActive {
        .left_side {
            transform: translateX(0);
            transition: transform 1.2s ease-in-out; // Przywraca animację przejścia
        }
    }
}

.left_side {
    width: 60%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    transition: transform 1.2s ease-in-out;
}

.right_side {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text_wrapper {
    width: 75%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 26px;
    color: white;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);

    .red_background,
    .dark_background {
        padding: 5px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .red_background {
        background-color: variables.$button_red_color;
    }

    .dark_background {
        background-color: variables.$dark_grey_color;
    }

    .margin_80 {
        margin-left: 80px;
        width: 40%;
        margin-top: 20px;
    }

    .margin_60 {
        margin-left: 60px;
        width: 30%;
    }

    .margin_40 {
        margin-left: 40px;
        width: 40%;
    }

    .margin_bottom {
        margin-left: 30px;
        margin-bottom: 20px;
        width: 80%;
    }
}