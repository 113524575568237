@use '../../../variables';

.table_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        height: 50px;
        border-bottom: solid 1px variables.$app_switch_grey_color;

        .column {
            display: flex;
            align-items: center;
            font-size: 13px;
            width: 20%;
            margin-left: 10px;
        }

        .bold {
            font-weight: 600;
            font-size: 16px;
        }

        &:hover {
            background-color: variables.$app_switch_grey_color;
            transition: background-color 0.3s;
        }
    }

    .no_hover:hover {
        background-color: initial;
        transition: none;
    }

    .odd_row {
        background-color: variables.$light_grey_color;
    }
}