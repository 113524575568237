@use '../../../variables';

.container {
    margin-top: 3%;

    .header {
        margin-top: 3%;

        p {
            font-size: 28px;
            font-weight: 600;
            color: variables.$app_default_red_color;
        }

    }
}