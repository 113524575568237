@use '../../../../variables';

.wrapper {
    height: 40vh;

    .main_container {
        width: 100%;
        padding-top: 1%;
        border-radius: 4;
        display: flex;
        flex-direction: row;

        .menu {
            width: 25%;
            margin-top: 50px;
        }

        .basic_information {
            width: 30%;
            margin-top: 50px;
        }

        .form_container {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 2%;
            width: 45%;
            display: flex;

            justify-content: space-around;

            h4 {
                width: 100%;
            }

            .form_row {
                width: 100%;

                input {
                    width: 90%;
                    height: 40px;
                    background-color: variables.$light_grey_color;
                    border: solid 1px variables.$border_color;
                    padding: 2%;
                }

            }

            .submit_button {
                height: 30px;
                width: 150px;
                outline: none;
                border: none;
                cursor: pointer;
                color: variables.$basic_white_color;
                background-color: variables.$app_red_color;
            }
        }
    }
}