@use '../../variables';

.container {
    height: 50vh;
    width: 100%;

    .title {
        font-size: 22px;
        color: variables.$basic_static_page_header_color;
        font-weight: light;
        letter-spacing: 1px;
    }

    .row {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .btns_container {
            margin-top: 20px;
            width: 20%;
            display: flex;
            justify-content: space-around;
        }

        .btn {
            width: 160px;
            height: 45px;
            background-color: variables.$basic_static_page_header_color;
            color: variables.$basic_white_color;
            border: none;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }

    }
}