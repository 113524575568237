@use '../../../variables';

.dropdownContainer {
    position: relative;
    display: inline-block;
    transition: height 0.3s ease;
    width: 400px;
    margin-bottom: 10px;

    .dropdownButton {
        color: variables.$footer-border-color;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
            content: "";
            display: inline-block;
            margin-right: 8px;
            border-width: 6px 6px 0 6px;
            border-style: solid;
            border-color: black transparent transparent transparent;
            transition: transform 0.3s ease;
            align-self: center;
        }

        &.dropdownButtonOpen::before {
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent black transparent;
            transform: rotate(1);
        }
    }

    .dropdownContent {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.3s ease;
        width: 100%;
        height: 40px;
        border-radius: 5px;

        border: solid 1px;
    }

    .dropdownContentOpen {
        max-height: 500px;
    }

    .checkboxesDropdownContent {
        height: 300px;
        width: 100%;
        position: absolute;
        border: solid 1px;
        margin-top: 10px;
        background-color: variables.$basic_white_color;
        border-radius: 6px;
        z-index: 2;
    }
}