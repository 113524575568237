@use '../../variables';

.container {
    height: 90%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$app_switch_grey_color;

    .two_dimensions,
    .three_dimensions {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .image_container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 70%;
            }

        }

        .text_container {
            width: 100%;
            height: 100%;
        }

    }

    .three_dimensions {
        color: variables.$basic_white_color;
        background-color: variables.$app_red_color;

    }
}