@use '../../variables';

.container {
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%;

    .search_container {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
            width: 50%;
        }
    }

    .additional_checkboxes {
        margin-top: 5%;

        p {
            font-weight: bold;
        }
    }

    .buttons_container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            border: none;
            width: 100px;
            height: 40px;
            font-weight: 500;
            color: variables.$basic_white_color;
            background-color: variables.$app_red_color;
        }
    }
}