@use '../../variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .product_image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        img {
            height: 180px;
            width: 180px;
        }

    }

    .product_name {
        width: 100%;
        height: 10%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: variables.$basic_black_color;
        font-weight: 600;
        cursor: pointer;
        color: black;
        text-decoration: none;
    }

}