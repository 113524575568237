@use '../../../variables';

.dropdown_container {
    position: relative;
    display: inline-block;
    width: 100%;

    .dropdownButton {
        color: variables.$footer-border-color;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
            content: "";
            display: inline-block;
            margin-right: 8px;
            border-width: 6px 6px 0 6px;
            border-style: solid;
            border-color: black transparent transparent transparent;
            align-self: center;
        }

        &.dropdownButtonOpen::before {
            transform: rotate(1deg);
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent black transparent;
        }
    }



    .slider {
        position: relative;
        width: 300px;
        transition: max-height 0.3s ease;
        padding-bottom: 10%;

        .slider_values {
            position: relative;
            height: 20px;
            margin-bottom: 20px;

            .value {
                position: absolute;
                font-size: 14px;
                color: white;
                background-color: variables.$basic_red_color;
                height: 25px;
                padding: 2px;
                width: 40px;
                text-align: center;
            }
        }

        .slider_track,
        .slider_range {
            position: absolute;
        }

        .slider_track {
            border-radius: 3px;
            height: 5px;
            background-color: variables.$app_red_color;
            width: 100%;
            z-index: 1;
        }

        .slider_range {
            border-radius: 3px;
            height: 8px;
            background-color: variables.$app_red_color;
            z-index: 1;
        }

        .thumb,
        .thumb::-webkit-slider-thumb {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
        }

        .thumb {
            pointer-events: none;
            position: absolute;
            height: 0;
            width: 300px;
            outline: none;

            &.thumb_left,
            &.thumb_right {
                z-index: 3;

                &::-webkit-slider-thumb {
                    background-color: variables.$app_red_color;
                    border: none;
                    cursor: pointer;
                    height: 20px;
                    width: 5px;
                    margin-top: 4px;
                    pointer-events: all;
                    position: relative;
                }

                &::-moz-range-thumb {
                    background-color: variables.$app_red_color;
                    border: none;
                    cursor: pointer;
                    height: 20px;
                    width: 10px;
                    margin-top: 4px;
                    pointer-events: all;
                    position: relative;
                }
            }
        }
    }

}