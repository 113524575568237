.wrapper {
    padding-left: 30px;
    width: calc(45vw - 40px);
    padding-bottom: 30px;
    margin-left: 10%;

    .item {
        display: flex;
        align-items: center;

        cursor: pointer;

        img {
            background: white;
            border-radius: 100%;
            -webkit-box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
            -moz-box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
            box-shadow: 5px 5px 10px 0px rgba(51, 51, 51, 1);
            width: 8vw;
            height: 8vw;
            padding: 10px;
        }


        h3 {
            font-size: 1.4vw;
            width: 100%;
        }
    }
}

@media screen and (max-width: 990px) {
    .wrapper {
        width: calc(75vw - 50px);
    }
}