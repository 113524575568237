.main_container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    .content {
        flex: 1;
    }

    .footer {
        margin-top: auto;
    }
}