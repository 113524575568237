@use '../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    font-family: variables.$font-family;
    min-height: 40px;
    width: 100%;

    .upper_part {
        height: 100px;
        display: flex;
        font-family: variables.$font-family;
        width: 100%;

        .left_side {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 50%;

            .logo_wrapper {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
            }

            .title_wrapper {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    color: variables.$app_red_color;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            @media(max-width: variables.$breakpoint-extra-large) {
                .title_wrapper {
                    display: none;
                }
            }
        }

        .right_side {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            align-items: center;
            width: 70%;
            padding: 1%;
            height: 100%;

            .top_bar_actions_wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 2%;

                .language_select_container {
                    width: 15%;
                    height: 40%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: solid 1px;

                    select {
                        cursor: pointer;
                        margin: 0;
                        width: 90%;
                        height: 30px;
                        border: solid 1px variables.$border_color;
                        font-weight: lighter;
                    }
                }

                .toggle_switch_container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 10%;
                    height: 40%;
                    border-right: solid 1px;

                }

                .header_element {
                    width: auto;
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 40%;
                    display: flex;
                    align-items: center;
                    border-right: solid 1px;
                    font-size: 12px;
                    font-weight: bold;
                    cursor: pointer;

                    span {
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }

                .column {
                    flex-direction: column;
                }

                .login_container,
                .logout_container,
                .register_container {
                    width: 11%;
                    height: 40%;
                    display: flex;
                    align-items: center;
                    border-right: solid 1px;
                    font-size: 12px;
                    font-weight: bold;
                    cursor: pointer;

                    span {
                        width: 100%;
                        text-align: center;
                    }

                    img {
                        height: 100%;
                    }
                }

                .register_container {
                    border-right: none;
                    margin-right: 3%;
                }
            }
        }


        @media(max-width: variables.$breakpoint-extra-large) {
            .right_side {
                width: 70%;
            }

        }

    }

    .bottom_part {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: variables.$app_red_color;
        width: 100%;

        .links_container {
            display: flex;
            align-items: center;
            font-family: variables.$font_family;
            text-transform: uppercase;
            width: 80%;
            margin-left: 10%;
            height: 100%;

            .link_div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 9%;
                text-decoration: none;
                color: variables.$basic_white_color;
                font-size: 16px;
                padding-left: 1%;
                padding-right: 1%;

                a {
                    text-decoration: none;
                    color: variables.$basic_white_color;
                    font-size: 16px;
                    font-weight: lighter;

                    &:last-child {
                        margin-right: 0;
                    }
                }

            }

            .link_div:hover,
            .selected_link {
                background-color: variables.$basic_grey_color;
            }

            .dropdown_container {
                position: relative;
                cursor: pointer;
                color: variables.$basic_white_color;

                span {
                    text-align: center;
                }

                img {
                    height: 20px;
                    width: 20px;
                }

                .dropdown_label {
                    font-size: 16px;
                    text-transform: uppercase;
                    color: variables.$basic_white_color;
                }

                .dropdown_menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: variables.$app_red_color;
                    flex-direction: column;
                    width: 100%;
                    z-index: 10;
                    display: none;

                    a {
                        padding: 10px;
                        text-decoration: none;
                        color: variables.$basic_white_color;
                        font-size: 14px;
                        transition: background-color 0.3s;
                        display: flex;
                        justify-content: space-between;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        &:hover {
                            background-color: variables.$basic_grey_color;
                        }
                    }


                }

                &:hover .dropdown_menu {
                    display: flex;
                }
            }
        }


        @media(max-width: variables.$breakpoint-ultra-large) {
            .links_container {
                margin-left: 5%;
                width: 70%;
            }

        }

        @media(max-width: variables.$breakpoint-extra-large) {
            .links_container {
                width: 75%;

                .link_div {
                    font-size: 14px;
                }
            }

        }

        @media(max-width: variables.$breakpoint-large) {
            .links_container {
                width: 80%;

                .link_div {
                    font-size: 12px;
                }
            }

        }
    }
}