@use '../../../../variables';


.header {
    width: 100%;
    display: flex;
    justify-content: center;
    color: variables.$basic_black_color;
    font-size: 25px;
    font-family: 'Open Sans', Arial, sans-serif;
    visibility: visible;
    text-transform: none;
}