@use '../../variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        border-top: solid 5px variables.$button_red_color;
        height: 60px;
        width: 100%;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        background-color: variables.$light_grey_color;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu_row {
        display: flex;
        border-bottom: solid 1px variables.$border_color;
        text-align: left;
        padding-left: 10%;
        padding-bottom: 1%;
        display: flex;
        align-items: center;
        font-size: 14px;
        text-decoration: none;
        color: variables.$basic_grey_color;
    }

    .icon {
        margin-right: 8px;
    }
}